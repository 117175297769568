* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.boxContainer {
  justify-content: center;
  width: 100%;
}
.theBox {
  height: 200px;
  width: 400px;
  margin: auto;
}
.title {
  width: 100%;
}
.result-box {
  margin: 30px;
  font-size: 25px;
}
.answers {
  margin: 20px;
  font-size: 25px;
  width: 135px;
  align-items: center;
  border: none;
  background-color: lightcyan;
  border-radius: 5px;
}

.answers:hover {
  background-color: cyan;
}
.explanation {
  max-width: 500px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.lecture {
  font-size: larger;
}
